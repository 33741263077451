import React from 'react'
import tw, { styled } from 'twin.macro'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import Certification from '../components/certification'
import SEO from '../components/seo'

const Wrapper = styled.div`
  ${tw`w-full lg:w-3/4 py-10 lg:py-20 mx-auto`};
`
const Title = styled.h2`
  ${tw`text-black dark:text-white font-normal text-3xl md:text-5xl`};
`
const Body = styled.div`
  ${tw`text-lg`};
`

const Section = styled.section`
  ${tw``};
`
const Certs = styled.div`
  ${tw`flex flex-wrap mt-8`};
`

class CertificationsPage extends React.Component {
  render() {
    const [certPage] = get(this, 'props.data.allContentfulCertifications.edges')
    const certs = get(this, 'props.data.allContentfulCertificationItem.edges')
    return (
      <>
        <SEO
          title="Certifications | James Does Digital"
          keywords={[
            `developer`,
            `blog`,
            `articles`,
            `aws`,
            `cloud`,
            `typescript`,
            `react native`,
            `react`,
            `graphql`,
          ]}
        />
        <Wrapper>
          <Title>{certPage.node.headline}</Title>
          <Body
            dangerouslySetInnerHTML={{
              __html: certPage.node.certificationsBodyText.childMarkdownRemark.html,
            }}
        />
        <Section>
          <Certs>
            {certs.map(({ node }) => {
              return <Certification cert={node} key={node.name} />
            })}
          </Certs>
        </Section>
        </Wrapper>
      </>
    )
  }
}

export default CertificationsPage

export const pageQuery = graphql`
  query CertificationsQuery {
    allContentfulCertifications {
      edges {
        node {
          headline
          certificationsBodyText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulCertificationItem(
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          name
          date
          link
          image {
            gatsbyImageData(
              quality: 95
              width: 450
              height: 450
              layout: FULL_WIDTH
              placeholder: TRACED_SVG
            )
          }
        }
      }
    }
  }
`
